<template>
  <div class="warm_prompt">
    <h4>温馨提示：</h4>
    <p>1.开户行（票交所）填写说明：票交所要求对银行卡进行再次小额鉴权。企业用户可模糊筛选出票交所支持的开户行，如果没有匹配的，请联系客服。</p>
    <p>2.账户生效日、账户到期日 填写说明：当账户类别=临时存款账户时，账户生效日、账户到期日才需要填写。</p>
  </div>
</template>

<script>
export default {
  name: "WarmPrompt"
}
</script>

<style scoped>
  /*账户信息 - 新增温馨提示*/
  .warm_prompt{
    background: #FFFBE6;
    width: 100%;
    line-height: 26px;
    padding: 8px 15px;
    margin-top: 20px;
    border: 1px solid #FFFBE6;
    box-sizing: border-box;
  }
  .warm_prompt p{
    font-size: 12px;
    color: #666;
    text-indent: 2em;
  }
</style>