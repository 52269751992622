<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>企业管理 </el-breadcrumb-item>
			<el-breadcrumb-item>企业信息更新</el-breadcrumb-item>
			<el-breadcrumb-item>更新账户信息</el-breadcrumb-item>
			<el-breadcrumb-item>详情</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<!--账户信息-->
			<h3>账户信息</h3>
      <WarmPrompt></WarmPrompt>
			<el-table :data="info" border class="bankAccountList mt20">
				<el-table-column prop="bankName" label="账户名称" min-width="100px"></el-table-column>
				<el-table-column prop="bankNumber" label="账号" min-width="100px"></el-table-column>
				<el-table-column prop="openBank" label="开户行" min-width="100px"></el-table-column>
				<el-table-column prop="provinceId" label="开户省" min-width="100px"></el-table-column>
				<el-table-column prop="cityId" label="开户市" min-width="100px"></el-table-column>
				<el-table-column prop="clearingNumber" label="联行号" min-width="120px"></el-table-column>
				<el-table-column prop="bankBranch" label="所属支行名称" min-width="180px"></el-table-column>
				<el-table-column prop="isCloseAccount" label="是否结算账户" min-width="140px">
					<template v-slot="scope">
						<span v-if="scope.row.isCloseAccount == 1">是</span>
						<span v-else>否</span>
					</template>
				</el-table-column>
				<el-table-column prop="accountType" label="账户类别" min-width="140px"></el-table-column>
				<el-table-column prop="accountStartDay" label="账户生效日" min-width="180px"></el-table-column>
				<el-table-column prop="accountEndDay" label="账户到期日" min-width="180px"></el-table-column>
				<el-table-column prop="smallValidation" label="小额验证状态" min-width="120px">
					<template v-slot="scope">
						<span v-if="scope.row.smallValidation == 0">未通过</span>
						<span v-else-if="scope.row.smallValidation == 1">通过</span>
						<span v-else-if="scope.row.smallValidation == 2">打款申请通过</span>
					</template>
				</el-table-column>
			</el-table>
			<!--银行账户资料-->
			<div class="bank-info-box">
				<div class="file-title">
					<h3>银行账户资料</h3>
<!--					<a href="javascript:;" class="look-example">查看示例</a>-->
				</div>
				<div class="upload-explain">
					<p>
						<img src="~@/assets/img/ticket/explain.png" alt="">上传说明：加盖公章后再上传
					</p>
					<span>
					基本账户：开户许可证复印件<br/>
					一般账户：银行流水回单，网银截屏或者其他显示账户信息的复印件<br/>
          <!--个人账户：银行卡号正反面复印件或者存折账号页复印件，复印件上请写上开户姓名及开户网点-->
				</span>
				</div>
				<ul class="bank-info">
					<li v-for="item in info" :key="item.id">
						<p>账号：{{item.bankNumber}}</p>
						<div class="upload-box">
							<div class="upload-thumbnail">
								<img class="upload-img" :src="item.bankAccountData" alt="">
							</div>
						</div>
					</li>
				</ul>
			</div>
		</el-card>
	</div>
</template>

<script>
  /*温馨提示*/
  import WarmPrompt from '@/views/Ticket/childrenCpn/WarmPrompt/Index'
  export default {
    name: "UpdateAccountDetail",
	  mounted() {
      // 获取详情页数据
      this.getInfo()
    },
    components: {
      WarmPrompt
    },
	  data(){
      return {
        // 查询参数
        searchForm: {
          id: this.$route.query.id,
          type: this.$route.query.type
        },
        // 获取到的数据
	      info: []
      }
	  },
	  methods: {
      // 获取详情页数据
      async getInfo(){
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/update-record/getDetailById",{params: this.searchForm})
        if(res.code !== 200) return this.$message.error(res.msg)
	      this.info = res.data
		  }
	  }
  }
</script>

<style lang="less" scoped>
	/*银行账户资料*/
	.bank-info-box{
		.look-example{
			margin-left: 15px;
		}
		.upload-explain{
			background: #FFFBE6;
			width: 100%;
			line-height: 1.8;
			padding: 10px 15px;
			margin-top: 20px;
			border: 1px solid #FFFBE6;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			span{
				display: flex;
				line-height: 1.8;
				color: #666;
				padding-left: 20px;
				margin-top: 10px;
			}
			img{
				vertical-align: -2px;
				margin-right: 5px;
			}
			a{
				color: #C70009;
			}
		}
		.bank-info{
			li{
				margin-top: 20px;
				.upload-box{
					display: flex;
					margin-top: 10px;
					margin-left: 30px;
				.upload-demo{
					margin-left: 20px;
				}
				.upload-thumbnail{
					width: 148px;
					height: 148px;
					height: 148px;
					border-radius: 6px;
					border: 1px dashed #c0ccda;
					box-sizing: border-box;
				img{
					width: 100%;
					height: 100%;
				}
			}
		}
		}
		}
	}
</style>